<template>
  <el-scrollbar :native="false">
    <div style="max-height: 100vh;">
      <el-dialog
        :visible.sync="showModal"
        fullscreen
        :show-close="false"
        custom-class="editContentItemModal"
        destroy-on-close
      >
        <el-form ref="contentForm" :rules="rules" :model="contentForm">
          <el-row type="flex">
            <el-col :span="12" :offset="6">
              <page-header :title="__('Move Phone numbers')" />
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="12" :offset="6">
              <el-form-item :label="__('Service Provider')" prop="sp_id">
                <el-select
                  value-key="sp_id"
                  v-model="contentForm.sp_id"
                  :placeholder="__('Select')"
                  style="width: 100%"
                  @change="handleSpSelection"
                  filterable
                  default-first-option
                >
                  <el-option
                    v-for="serviceProvider in this.serviceProviders"
                    :key="serviceProvider.sp_id"
                    :label="serviceProvider.sp_name"
                    :value="serviceProvider.sp_id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="12" :offset="6">
              <el-row style="height: 30px;">
                <el-form-item :label="__('Phone Numbers')" required>
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item>
                  <el-radio-group v-model="contentForm.moveMethod">
                    <el-radio label="copy-paste">{{
                      __("Copy/paste comma separated list")
                    }}</el-radio>
                    <el-radio label="csv">{{ __("Upload CSV file") }}</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-row>
            </el-col>
          </el-row>
          <el-row type="flex" v-if="contentForm.moveMethod === 'copy-paste'">
            <el-col :span="12" :offset="6">
              <el-form-item prop="phone_number">
                <el-input
                  type="textarea"
                  :rows="8"
                  :placeholder="__('please input comma separated list')"
                  v-model="contentForm.phone_number"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" v-if="contentForm.moveMethod === 'csv'">
            <el-col :span="12" :offset="6">
              <el-form-item prop="phone_number">
                <csv-uploader
                  :file-name="file_name"
                  :file-url="contentForm.uploadedUrl"
                  folder="temp"
                  @on-success="handleSuccess"
                  @on-delete="handleDelete"
                  @on-progress="isLoading = true"
                  @on-error="handleError"
                />
              </el-form-item>
              <div style="margin-bottom: 20px;">
                <el-row>
                  <span style="font-size: medium">{{
                    __("Unsure of csv format?")
                  }}</span>
                  <el-button
                    class="downloadCsvStyle"
                    type="text"
                    @click="downloadPhoneNumberSampleFile"
                    style="margin-left: 10px;"
                  >
                    {{ __("Download Sample") }}</el-button
                  >
                </el-row>
              </div>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="12" :offset="6">
              <el-row style="height: 30px;">
                <el-form-item :label="__('Move Phone numbers to:')">
                </el-form-item>
              </el-row>
              <el-row>
                <span style="font-size: small">
                  <!-- eslint-disable-next-line -->
                  {{ __("You can unassign account and task together or unmap task only.") }}
                  <br />
                  <!-- eslint-disable-next-line -->
                  {{ __("Note: If you want to bulk assign to account or task please do not select any action from below.") }}
                </span>
              </el-row>
              <el-row style="margin-top: 15px; margin-bottom: 30px;">
                <el-radio-group v-model="contentForm.unassign">
                  <el-radio label="unassign">{{ __("Unassign") }}</el-radio>
                  <el-radio label="unmap">{{ __("Unmap") }}</el-radio>
                </el-radio-group>
              </el-row>
            </el-col>
          </el-row>
          <div v-loading="spLoading">
            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item :label="__('Account')" prop="ac_id">
                  <el-select
                    filterable
                    value-key="ac_id"
                    v-model="contentForm.ac_id"
                    :placeholder="__('Select')"
                    style="width: 100%"
                    :disabled="
                      contentForm.unassign !== '' || contentForm.sp_id === ''
                    "
                    @change="handleAcSelection"
                    default-first-option
                  >
                    <el-option
                      v-for="account in this.selectedSpAccounts"
                      :key="account.ac_id"
                      :label="account.ac_name"
                      :value="account.ac_id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-row style="height: 30px;">
                  <el-form-item :label="__('Channel')" required> </el-form-item>
                </el-row>
                <el-row>
                  <span style="font-size: small">
                    <!-- eslint-disable-next-line -->
                    {{ __("Warning: A phone number will be ignored if it does not match with channel.") }}
                  </span>
                </el-row>
                <el-row style="margin-top: 10px;">
                  <el-form-item prop="channel">
                    <el-select
                      value-key="channel"
                      v-model="contentForm.channel"
                      :placeholder="__('Select')"
                      style="width: 100%"
                      :disabled="
                        contentForm.unassign !== '' || contentForm.ac_id === ''
                      "
                      @change="handleChannelSelection"
                      filterable
                      default-first-option
                    >
                      <el-option
                        v-for="channel in this.channels"
                        :key="channel"
                        :label="channel"
                        :value="channel"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-row>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item label="Task" prop="task_id">
                  <el-select
                    value-key="task_id"
                    v-model="contentForm.task_id"
                    placeholder="Select"
                    style="width: 100%"
                    :disabled="
                      contentForm.unassign !== '' ||
                        contentForm.ac_id === '' ||
                        contentForm.channel === ''
                    "
                    filterable
                    default-first-option
                  >
                    <el-option
                      v-for="task in this.selectedAcTasks"
                      :key="task.task_id"
                      :label="task.task_name"
                      :value="task.task_id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <el-row type="flex">
            <el-col :span="12" :offset="6">
              <el-row style="height: 30px;">
                <el-form-item
                  :label="
                    __('Allow already assigned phone numbers to be moved?')
                  "
                >
                </el-form-item>
              </el-row>
              <el-row>
                <span style="font-size: small">
                  <!-- eslint-disable-next-line -->
                  {{ __("Warning: This will allow phone numbers to be moved that are already assigned to other tasks.") }}
                </span>
              </el-row>
              <el-row style="margin-top: 10px;">
                <el-form-item
                  ><el-checkbox
                    v-model="contentForm.allow"
                    :disabled="contentForm.unassign !== ''"
                    >{{ __("Allow") }}</el-checkbox
                  ></el-form-item
                >
              </el-row>
            </el-col>
          </el-row>
        </el-form>
        <div
          slot="footer"
          style="display: flex;margin-left: 25%;margin-bottom: 20px"
        >
          <el-button
            type="primary"
            class="submitBtn"
            @click="submitForm"
            :loading="loading"
            :disabled="
              (contentForm.phone_number === '' &&
                contentForm.moveMethod === 'copy-paste') ||
                (contentForm.uploadedUrl === '' &&
                  contentForm.moveMethod === 'csv')
            "
            >Move
          </el-button>
          <el-button class="cancelBtn" @click="handleCancel">{{
            __("Cancel")
          }}</el-button>
        </div>
      </el-dialog>
    </div>
  </el-scrollbar>
</template>

<script>
import { EventBus } from "@/EventBus";
import CsvUploader from "../../../../../components/uploaders/types/CsvUploader";
import axios from "axios";
import PageHeader from "@/components/PageHeader";
import { mapActions, mapState } from "vuex";
import BaseUploader from "../../../../../components/uploaders/BaseUploader";
import FileDownloader from "@/mixins/FileDownloader";

export default {
  name: "SysTelephonyPhoneNumbersBulkMove",
  mixins: [BaseUploader, FileDownloader],
  components: {
    PageHeader,
    CsvUploader
  },
  props: {
    openModal: {
      type: Boolean,
      required: false,
      default: false
    },
    fileUrl: {
      default: ""
    }
  },
  computed: {
    ...mapState("app", {
      selectedServiceProviderId: state => state.selectedServiceProviderId
    }),

    ...mapState("phonenumbers", {
      selectedServiceProviderId: state => state.selectedServiceProviderId,
      loading: state => state.loading
    }),

    ...mapState("serviceproviders", {
      serviceProviders: state => state.serviceProviders,
      selectedSpAccounts: state => state.selectedSpAccounts,
      selectedSpTrunks: state => state.selectedSpTrunks,
      selectedAcTasks: state => state.selectedAcTasks,
      spLoading: state => state.loading
    })
  },
  data() {
    const checkSpId = (rule, value, callback) => {
      if (this.contentForm.sp_id === "") {
        callback(__("Service provider is required"));
      } else {
        callback();
      }
    };
    const checkPhoneNumber = (rule, value, callback) => {
      if (
        this.contentForm.phone_number === "" &&
        this.contentForm.uploadedUrl === ""
      ) {
        callback(__("Service provider is required"));
      } else {
        callback();
      }
    };
    const checkAcId = (rule, value, callback) => {
      if (this.contentForm.unassign === "" && this.contentForm.ac_id === "") {
        callback(__("Account is required"));
      } else {
        callback();
      }
    };
    const checkChannel = (rule, value, callback) => {
      if (this.contentForm.unassign === "" && this.contentForm.channel === "") {
        callback(__("Channel is required"));
      } else {
        callback();
      }
    };
    return {
      contentForm: {
        sp_id: "",
        phone_number: "",
        moveMethod: "copy-paste",
        unassign: "",
        ac_id: "",
        task_id: "",
        uploadedUrl: "",
        allow: false,
        channel: "",
        type: "sys-telephony"
      },
      file_name: "",
      showModal: false,
      channels: ["voice", "sms", "whatsapp", "wavecell"],
      rules: {
        sp_id: [
          {
            required: true,
            trigger: "blur",
            message: __("Service Provider is required"),
            validator: checkSpId
          }
        ],
        phone_number: [
          {
            required: true,
            trigger: "blur",
            message: __("phone numbers are required"),
            validator: checkPhoneNumber
          }
        ],
        ac_id: [
          {
            required: true,
            trigger: "blur",
            message: __("Account is required"),
            validator: checkAcId
          }
        ],
        channel: [
          {
            required: true,
            trigger: "blur",
            message: __("channel is required"),
            validator: checkChannel
          }
        ]
      }
    };
  },
  methods: {
    ...mapActions("serviceproviders", {
      fetchAccountsForSelectedSP: "fetchAccountsForSelectedSP",
      getServiceProviders: "getServiceProviders",
      fetchTasksForSelectedAc: "fetchTasksForSelectedAc"
    }),
    ...mapActions("phonenumbers", {
      movePhoneNumbers: "movePhoneNumbers"
    }),
    async handleSpSelection(sp) {
      await this.fetchAccountsForSelectedSP(sp);
    },
    async handleAcSelection(ac) {
      this.contentForm.task_id = "";
      if (this.selectedAcTasks.length === 0) {
        if (this.contentForm.channel !== "") {
          await this.handleChannelSelection(this.contentForm.channel);
        }
      } else {
        if (this.selectedAcTasks[0].ac_id !== ac) {
          await this.handleChannelSelection(this.contentForm.channel);
        }
      }
    },
    async handleChannelSelection(channel) {
      this.contentForm.task_id = "";
      let task_type = "all";
      switch (channel) {
        case "sms":
          task_type = "messaging";
          break;
        default:
          task_type = channel;
          break;
      }
      await this.fetchTasksForSelectedAc({
        ac: this.contentForm.ac_id,
        task_type
      });
    },
    initContent() {
      this.contentForm.sp_id = "";
      this.contentForm.phone_number = "";
      this.contentForm.moveMethod = "copy-paste";
      this.contentForm.unassign = "";
      this.contentForm.ac_id = "";
      this.contentForm.task_id = "";
      this.contentForm.uploadedUrl = "";
      this.contentForm.allow = false;
      this.contentForm.channel = "";
    },
    handleCancel() {
      this.initContent();
      this.$emit("cancel");
    },
    handleRemove(file) {
      console.log(file);
    },
    handleSuccess(res) {
      console.log("upload res" + res.url);
      this.contentForm.uploadedUrl = res.path;
      //this.uploadedFilePath = res.path;
    },
    handleError(err) {
      console.log(err);
    },
    handleDelete() {
      this.resetForm();
    },
    resetForm() {
      this.contentForm.uploadedUrl = "";
      this.file_name = "";
    },
    submitForm() {
      this.$refs.contentForm.validate(valid => {
        if (valid) {
          const process = this.movePhoneNumbers;
          process(this.contentForm)
            .then(data => {
              this.$message({
                name: "success",
                message: data.message
              });
              EventBus.$emit("list-changed", data.data);
              this.handleCancel();
            })
            .catch(err => {
              this.isSubmitting = false;
              this.$message({
                type: "error",
                message: err.response.data.message
              });
            });
        }
      });
    },
    downloadPhoneNumberSampleFile() {
      axios({
        url: "../../csv/BulkMovePhoneNumberSample.csv",
        method: "GET",
        responseType: "blob" // important
      }).then(response => {
        this.downloadFile(response.data, "BulkMovePhoneNumberSample.csv");
      });
    }
  },
  watch: {
    openModal: {
      immediate: true,
      handler(val) {
        this.showModal = val;
      }
    }
  }
};
</script>

<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/content-edit-info.scss";
@import "~@/styles/tags.scss";
@import "~@/styles/node_common.scss";

.top {
  text-align: center;
}
.item {
  margin: 4px;
}
::v-deep .el-radio {
  :hover {
    border-color: $content-theme-color;
  }
  .el-radio__input.is-checked .el-radio__inner {
    border-color: $content-theme-color;
    background: $content-theme-color;
  }
  .el-radio__input.is-checked + .el-radio__label {
    color: $content-theme-color;
  }
}
::v-deep .el-checkbox {
  :hover {
    border-color: $content-theme-color;
  }
  .el-checkbox__input.is-checked .el-checkbox__inner {
    border-color: $content-theme-color;
    background: $content-theme-color;
  }
  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: $content-theme-color;
  }
}
::v-deep .el-upload-dragger {
  &:hover {
    border-color: $content-theme-color !important;
  }
  .el-upload__text em {
    color: $content-theme-color;
  }
}
.downloadCsvStyle {
  color: $content-theme-color;
}
</style>
