<template>
  <div style="width: 100%;" @click="handleClear">
    <el-button
      class="createBtn"
      @click.stop="createContentItem"
      v-show="this.canWrite('phone_numbers')"
      >{{ __("Add Phone Number") }}
    </el-button>
    <div style="padding: 0 36px; margin-top: 165px;">
      <el-form class="form-container">
        <PaginationToolbar
          :sort-by-options="sortByOptions"
          :content-api="contentAPI"
          :show-slot="true"
          @success="$emit('replaceContent', $event)"
        >
          <el-button
            class="bulkMoveBtn"
            @click="handleBulkMove"
            slot="buttonGroup"
            v-show="this.canWrite('phone_numbers')"
            ><img
              :src="getBulkUploadImage"
              class="bulkImg"
              onload="SVGInject(this)"
            />
          </el-button>
        </PaginationToolbar>
      </el-form>
      <bulk-move
        v-if="phoneNumbers.length"
        file-url=""
        :open-modal="isBulkMode"
        @cancel="handleBulkMove"
      ></bulk-move>
      <el-scrollbar :native="true">
        <div class="contentListContainer">
          <el-table
            ref="phoneNumbersTable"
            v-loading="loading"
            :data="phoneNumbers"
            @row-click="handleSingleClick"
            @row-dblclick="handleDoubleClick"
            highlight-current-row
            class="list-table"
            v-show="canRead('phone_numbers')"
          >
            <el-table-column :label="__('Phone number')" prop="phone_number" />
            <el-table-column :label="__('Service Provider')" prop="sp_name" />
            <el-table-column :label="__('Account')" prop="ac_name" />
            <el-table-column :label="__('Channel')" prop="channel" />
            <el-table-column :label="__('Task')" prop="task_name" />

            <el-table-column :label="__('Tags')">
              <template slot-scope="scope" v-if="scope.row.tags">
                <el-tag
                  v-for="tag in tagsArray(scope.row.tags)"
                  :key="tag"
                  style="padding: 0 3px 3px 3px;margin-left: 3px;"
                >
                  {{ tag }}
                </el-tag>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PaginationToolbar from "@/components/PaginationToolbar";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import BulkMove from "./BulkMove";

export default {
  name: "SysTelephonyPhoneNumbersList",
  mixins: [BaseContent],
  components: {
    PaginationToolbar,
    BulkMove
  },
  data() {
    return {
      sortByColumns: ["phone_number", "phone_number_id"],
      additionalValidateRoute: "phone-numbers",
      isBulkMode: false,
      showSlot: true
    };
  },

  computed: {
    ...mapState("phonenumbers", {
      phoneNumbers: state => state.phoneNumbers,
      loading: state => state.loading
    }),

    ...mapState("app", {
      selectedServiceProviderId: state => state.selectedServiceProviderId
    }),

    getBulkUploadImage() {
      return require("@/assets/icons/move-phone-numbers.svg");
    }
  },

  methods: {
    ...mapActions("phonenumbers", {
      contentAPI: "getSysPhoneNumbers",
      deleteContentMethod: "deletePhoneNumber",
      undoDeleteContent: "undoDeletePhoneNumber"
    }),
    handleClear() {
      this.$refs.phoneNumbersTable.setCurrentRow();
      this.handleClearSelection();
    },
    handleSingleClick(row, column, event) {
      event.stopPropagation();
      this.handleSelect(null, row);
    },

    handleDoubleClick(row, column, event) {
      event.stopPropagation();
      this.handleEdit(null, row);
    },

    handleBulkMove() {
      this.isBulkMode = !this.isBulkMode;
    },

    tagsArray(tagsString) {
      return tagsString.split(",", 2);
    }
  },
  watch: {
    selectedItemIndex: {
      immediate: true,
      handler: function(val) {
        if (val !== -1 && val !== null) {
          this.$refs.phoneNumbersTable.setCurrentRow(this.phoneNumbers[val]);
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
$card-image-filter: invert(65%) sepia(43%) saturate(5014%) hue-rotate(170deg)
  brightness(104%) contrast(89%);
@import "~@/styles/content-buttons.scss";
@import "~@/styles/pagination-toolbar.scss";
@import "~@/styles/content-list.scss";

.bulkMoveBtn {
  margin-left: 20px;
  height: 44px;
  width: 30px;
  border-color: #f5f5f8;
  color: #a0a8b5;

  &:hover {
    background-color: transparent;
    border-color: $content-theme-color;

    ::v-deep svg {
      fill: $content-theme-hover-color;

      path {
        fill: $content-theme-hover-color;
      }
    }

    .bulkImg {
      width: 24px;
      margin-left: -10px;
    }
  }

  &.is-disabled:hover,
  &.is-disabled {
    color: #a0a8b5;
    border-color: #f5f5f8;

    ::v-deep svg {
      fill: #a0a8b5;

      path {
        fill: #a0a8b5;
      }
    }
  }
}

.bulkImg {
  width: 24px;
  margin-left: -10px;
}
</style>
